#main-wrapper {
    width: 100% !important;
    height: 100% !important;
    background: rgb(32, 32, 37);
    /* display: flex;
    flex-direction: column;
    overflow-x: hidden; */

    overflow-y: hidden !important;

}

#scnd-main-wrapper {
    width: 100% !important;
    height: 100% !important;
    background: rgb(32, 32, 37);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden !important;
    position: relative;
}

.main-wrapper-float {
    width: 100%;
    display: flex;
    border: 0;
}

#proj-title {
    font-family: "Avenir Next", "Helvetica Neue",Helvetica,Arial,sans-serif;
    /* border: 1px solid darkgray; */
    width: 97%;
    /* margin-left: 2%; */
    /* color: rgb(68, 68, 70); */
    color: rgb(299, 299, 299);
    /* text-align: left; */
    display: flex;
    align-items: center;
    justify-items: center;
    text-align: center;
    justify-content: center;
    align-content: center;
}
#proj-title h1 {
    margin-bottom: 8px;
    margin-top: 0 !important;
}

#map-control {
    height: 80%;
    margin: 0;
    position: relative;
    width: calc(100% - .5% - 50px) !important;
}

#map-data-source {
    height: 11%;
    margin: 0;
    /* border: 1px solid rgb(68, 68, 70); */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    padding: 0;
    z-index: 200;
    transition: 125ms cubic-bezier(.25,.1,.25,1);
    /* overflow-y: auto; */
}
#map-data-source #header-holder h2 {
    /* color: rgb(299, 299, 299); */
    /* color: rgb(68, 68, 70); */
    color: #d3d3d3;
    margin-top: 4px;
    margin-left: 8px;
}
#map-data-source #svgContainer {
    position: absolute;
    top: -20px;
    left: auto;
    right: auto;
    cursor: pointer;
}

#map-data-source svg #upArrow, #map-data-source svg #downArrow{
    /* stroke: #d3d3d3;
    fill: #d3d3d3; */
    stroke: rgb(299, 299, 299);
    fill: rgb(299, 299, 299);
    stroke-width: 2;
}

#map-wrapper {
    width: 100%;
    height: 98%;
    border: 0;
    margin-left: 1%;
    display: flex;
    flex-direction: row;
    margin-top: 3.6px;
    /* padding: 0; */
    /* border: 1px solid rgb(68, 68, 70); */
}

/* #stats-cut {
    width: 20%;
    height: 98%;
    border: 1px solid rgb(68, 68, 70);
    border-radius: 4px;
    //comment this out margin-right: 1%; 
    position: absolute;
    right: 5%;
    top: 9.6px;
} */

.map-cut-inner {
    height: 100%;
    border: 1px solid rgb(68, 68, 70);
    border-radius: 4px;
}

#map-wrapper #map {
    width: 100%; 
    padding: 0;
    cursor: pointer;
}
#map-wrapper #map canvas{
    cursor: pointer;
}
#map-wrapper #map iframe{
    border-radius: 4px;
    cursor: pointer;
}

#map-wrapper #map-control-filters {
    width: 20%;
    margin-right: 1%;
    margin-left: 1%;
}

#source-data {
    width: 85%;
    height: 97%;
    margin-left: 1%;
    margin-right: 1%;
    border: 1px solid rgb(68, 68, 70);
    border-bottom: 1px solid transparent !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    /* background-color: rgb(68, 68, 70); */
    background-color: rgb(32, 32, 37);
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    bottom: 0;
}
#source-data::-webkit-scrollbar {
    width: .5em;
  }
   
#source-data::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    box-shadow: transparent;
    border-radius: 4px;    
}
   
#source-data::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: transparent;
    border-radius: 4px;
}
#data-holder {
    width: 99.9% !important;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    align-items: center;
    justify-items: center;
    /* justify-content: center;
    align-content: center; */
}
#data-holder::-webkit-scrollbar {
    width: .5em;
    margin-left: 1%;
}
   
#data-holder::-webkit-scrollbar-track {
    box-shadow: transparent;
    border-radius: 4px;    
}
   
#data-holder::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: transparent;
    border-radius: 4px;
}


#source-data .source-elem {
    /* margin-left: 14px;
    margin-right: 8px; */
    margin-bottom: 1%;
    padding: 12px 16px 14px;
    border: 1px solid rgb(68, 68, 70);
    width: 95%;
    /* background-color: #666666; */
    border-radius: 4px;
    color: #d3d3d3;
}
.source-elem .source-elem-bold {
    font-weight: bold;
}
.source-elem .source-elem-bold a {
    outline: none;
    text-decoration: none;
    color: #43A6FC;
    cursor: pointer;
}

#question-list-write-btn:hover span {
    visibility: visible;
}

#map-control-filters::-webkit-scrollbar {
    width: .5em;
    /* cursor: pointer; */
  }
   
#map-control-filters::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    /* cursor: pointer; */
}
   
#map-control-filters::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
    outline: transparent;
    border-radius: 4px;
    /* cursor: pointer; */
}

.esri-ui .esri-popup {
    border-radius: 4px !important;
}

.esri-popup__main-container {
    border-radius: 4px;
}

.esri-popup::-webkit-scrollbar {
    width: .5em;
    /* cursor: pointer; */
  }
   
.esri-popup::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    /* cursor: pointer; */
}
   
.esri-popup::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 4px;
    /* cursor: pointer; */
}

#locationTabsWrapper {
    display: flex;
    flex-direction: row;
    margin: 0;
    background: none !important;
    width: 700px;
    height: auto;
    padding-left: 4px;
    padding-right: 4px;
    box-shadow: none !important;
    visibility: hidden;
}

#locationTabsWrapper .locTab {
    font-size: 22px;
    margin-left: auto;
    padding: 3.5px;
    padding-bottom: 4px;
    border: 1px solid darkgray;
    border-radius: 4px;
    cursor: pointer;
    opacity: .7;
    transition: 125ms cubic-bezier(.25,.1,.25,1);
}
#locationTabsWrapper .locTab:hover {
    opacity: 1;
}

#floating-footer {
    z-index: 350;
    position: absolute;
    top: 6%;
    /* right: -220px; */
    /* right: -11.5%; */
    /* left: calc(5% + 100%-102.906px-.5%); */
    /* margin-left: calc(5% + 100% - .5% - 43px); */
    margin-left: calc(100% - .5% - 43px);
    right: .3%;
    /* width: max-content; */
    width: auto;
    height: 734px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    /* border: 1px solid rgb(68, 68, 70); */
    /* transform: rotate(-90deg); */
}
.float-footer-item {
    height: max-content;
    width: max-content;
    text-align: center;
    /* border: 1px solid rgb(68, 68, 70); */
    border: 1px solid darkgray;
    padding: 4px;
    padding-left: 6px;
    padding-right: 6px;
    color: darkgray;
    opacity: .7;
    font-size: 24px;
    font-family: "Avenir Next", "Helvetica Neue",Helvetica,Arial,sans-serif;
   /* font-weight: 300; */
   border-radius: 4px;
   writing-mode: tb-rl;
   text-orientation: sideways;
   cursor: pointer;
   /* transform: rotate(-90deg); */
   transition: 125ms cubic-bezier(.25,.1,.25,1);
   margin-top: 65px;
}
.float-footer-item:hover {
    /* border: 1px solid darkgray; */
    opacity: 1;
}
#feedback-floating-item {
    cursor: not-allowed;
    /* pointer-events: none; */
}

#side-menu {
    z-index: 350;
    position: absolute;
    top: 2%;
    /* right: 66px; */
    right: -541px;
    width: 475px;
    border: 1px solid darkgray;
    /* height: 834px; */
    height: 96%;
    border-radius: 4px;
    color: darkgray;
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
}
.side-menu-innerWrapper {
    height: 99%;
    width: 99%;
}
.side-menu-innerWrapper::-webkit-scrollbar {
    width: .2em;
    /* cursor: pointer; */
    outline: none;
  }
   
.side-menu-innerWrapper::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    border-radius: 4px;
    /* cursor: pointer; */
    outline: none;
}
   
.side-menu-innerWrapper::-webkit-scrollbar-thumb {
    /* background-color:rgb(28, 28, 28); */
    background-color: darkgray;
    border-radius: 4px;
    /* cursor: pointer; */
    outline: none;
}
.side-menu-innerWrapper .side-menu-title {
    margin-left: 4px;
    margin-top: 2px;
    color: rgb(299, 299, 299);
}
#theTeamWrapper #shortDesc{
    margin-left: 4px;
    color: rgb(299, 299, 299);
    font-weight: 400;
}
#theTeamWrapper {
    overflow-y: auto;
}
.bioCard {
    width: 90%;
    /* border: 1px solid; */
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    color: rgb(299, 299, 299);
    margin-bottom: 20px;
}
.bioCard img {
    width: 250px;
    border-radius: 4px;
    margin-bottom: 1.5%;
    /* border: 1px solid rgb(299, 299, 299); */
}
.bioCard h2, .bioCard p {
    background-color: rgb(32, 32, 37);
    padding: 12px 16px 14px;
    border-radius: 4px;
    margin-top: 0px;
    margin-bottom: 0;
    /* border: 1px solid rgb(299, 299, 299); */
}
.bioCard h2 {
    margin-bottom: .5%;
}
.bioCard p span {
    color: #43A6FC !important;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}
.bioCard p span a{
    color: #43A6FC !important;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

#paperWrapper {
    overflow-x: hidden;
    overflow-y: auto;
    color: rgb(299, 299, 299);
    background-color: rgb(32, 32, 37);
}
.paper-side-menu-title {
    margin-bottom: 8%;
}
#paper-textWrapper {
    margin-left: 8px;
}
#paper-textWrapper p {
    margin-left: 8px;
}

#paper-textWrapper p a {
    color: #43A6FC !important;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}
#paper-textWrapper p #paperTitle {
    font-weight: bold;
}





.esri-ui-bottom-left {
    opacity: .9;
    cursor: pointer;
    transition: 125ms cubic-bezier(.25,.1,.25,1);
}
.esri-ui-bottom-left:hover {
    opacity: 1;
}

.esri-legend {
    overflow: auto !important;
    overflow-y: auto !important;
}



.esri-legend::-webkit-scrollbar {
    width: .5em;
    /* cursor: pointer; */
    
  }
   
.esri-legend::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    /* cursor: pointer; */
    
}
   
.esri-legend::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
    outline: transparent;
    border-radius: 4px;
    /* cursor: pointer; */
    
}
