@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500&display=swap');

.App {
  font-family: Montserrat-medium, sans-serif;
  width: 100vw !important;
  height: 100vh !important;
  overflow-x: hidden;
  overflow-y: hidden;

  background: rgb(32, 32, 37);
}