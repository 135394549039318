#loaderWrapper2 {
    width: 100vw !important;
    z-index: 360;
    height: 100vh !important;
    position: absolute !important;
    backdrop-filter: blur(3px) !important;
}

.spinner2 {
    width: 40px;
    height: 40px;
  
    position: relative;
    /* margin: 100px auto; */
    justify-self: center;
    align-self: center;
    left: calc(50% - 20px);
    top: 50%;
  }
  
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #676567;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }

  #loader-tutorial {
    font-family: Montserrat-medium, sans-serif;
    font-size: 1em;
    text-align: center;
    align-self: center !important;
    justify-self: center;
    position: absolute;
    width: 50%;
    top: 40%;
    left: 25%;
    font-weight: 300;
    color: #676567;
  }
